<template>
  <div class="typeDynamicPreview">
    <div v-if="selectedContent">
      <div>
        <div
          v-if="selectedContent.type === 0"
          :style="
            liningStyles +
            `margin-top:${
              landing.advanced.global.active_header_inside ||
              landing.advanced.global.active_logo_inside
                ? '20px'
                : `${+landing.branding.topMenu.styles.size * 2.5}px`
            };padding:15px;min-height:300px;margin-left:${
              landing.branding.lining_margin ? '10px' : '0'
            };margin-right:${landing.branding.lining_margin ? '10px' : '0'};`
          "
        >
          <typeHtmlPreview :content="selectedContent.content[0]" />
          <FormOrderPreview :formOrder="selectedContent.content[0].form" />
          <SliderMenuPreview
            :active_slider="selectedContent.content[0].active_slider"
            :slider_menu="selectedContent.content[0].slider"
          />
        </div>
        <div v-if="selectedContent.type === 2">
          <template v-if="listing.page">
            <div
              :style="`padding:${
                landing.branding.lining_margin ? '0 10px' : '0'
              }`"
            >
              <typeListingDetailPreview
                :item="item"
                :content="selectedContent.content[2]"
                :listing="listing"
              />
            </div>
          </template>
          <template v-else>
            <div
              class="listing__list"
              :style="
                liningStyles +
                `margin-top:${
                  landing.advanced.global.active_header_inside ||
                  landing.advanced.global.active_logo_inside
                    ? '20px'
                    : `${+landing.branding.topMenu.styles.size * 2.5}px`
                };margin-left:${
                  landing.branding.lining_margin ? '10px' : '0'
                };margin-right:${
                  landing.branding.lining_margin ? '10px' : '0'
                };min-height:300px;`
              "
            >
              <typeListingListPreview
                :item="item"
                :content="selectedContent.content[2]"
                :type="'listing'"
              />
              <SliderMenuPreview
                :active_slider="selectedContent.content[2].active_slider"
                :slider_menu="selectedContent.content[2].slider"
              />
            </div>
          </template>
        </div>
        <div v-if="selectedContent.type === 3">
          <type-gallery-preview :content="selectedContent.content[3]" />
        </div>
      </div>
    </div>
    <div
      v-else
      style="
        border-radius: 5px;
        background: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 200px;
        margin: 65px 15px 0 15px;
        padding: 15px;
      "
    >
      Choose your button content
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import typeHtmlPreview from "./typeHtmlPreview.vue";
import typeListingListPreview from "./typeListingListPreview.vue";
import SliderMenuPreview from "./SliderMenuPreview";
import FormOrderPreview from "./FormOrderPreview.vue";
import typeListingDetailPreview from "./typeListingDetailPreview";
import TypeGalleryPreview from "@/components/Constructor/Menu/Preview/typeGalleryPreview";
export default {
  name: "typeDynamic",
  props: ["item", "content"],
  components: {
    TypeGalleryPreview,
    typeHtmlPreview,
    SliderMenuPreview,
    FormOrderPreview,
    typeListingListPreview,
    typeListingDetailPreview,
  },
  computed: {
    ...mapState("rooms", ["selectedContent"]),
    ...mapState(["listing", "landing"]),
    liningStyles() {
      if (this.landing.branding.bg_lining_img) {
        return `border-radius:${this.landing.branding.lining_radius}px;background-image:url(${this.landing.branding.bg_lining_img});background-size:cover;background-repeat:no-repeat;`;
      } else {
        return `border-radius:${this.landing.branding.lining_radius}px;background-color:${this.landing.branding.bg_lining_color};`;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.typeDynamicPreview {
  width: 100%;
  height: 100%;
}
</style>
