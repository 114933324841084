<template>

  <div v-if="active_slider" style="margin-top:15px;overflow:hidden;">
    <div v-for="(slider,slideGroupIndex) in sliderMenu" :key="slider.id">

      <div  style="padding:10px;" :style="`background-color:${slider.background};`">
        <div v-if="slider.title"  style="border-radius:0;margin:0 0 10px 0;padding:0;min-height: auto;height:auto;" v-html="slider.title"></div>

        <div v-if="slider.slider_view_all.show" style="float:right;margin-bottom:15px;">
          <a :href="slider.slider_view_all.link">{{slider.slider_view_all.text}}</a>
        </div>
        <swiper
            v-if="slider.type === 'horizontal'"
            ref="mySwiper"
            :options="swiperOptions"
            @clickSlide="handleClickSlide(slideGroupIndex)"
            
        >
          <swiper-slide v-for="(slide,i) in JSON.parse(JSON.stringify(slider.slides.filter(x => x.img)))"
                        :key="i" :style="`width: ${slider.horizontal.width}px;`"
                        >
<div style="display:flex;padding:10px 0 10px 10px;margin-right:10px;" :style="`background:${slider.bg_group};cursor:${slide.useLink?'pointer':'default'};border:1px solid ${slider.border_color};min-height:${slider.horizontal.height}px;width: ${slider.horizontal.width}px;border-radius:${landing.menu.radius}px;`">
  
            <div :class='`sliderHorizontal_${slideGroupIndex}_${i}`' style="overflow:hidden;min-height:100%;" :style="`max-width:${slider.horizontal.img_width}px;width:${slider.horizontal.img_width}px;border-top-left-radius:${slider.radius}px;border-bottom-left-radius: ${slider.radius}px;`">
              <img :src="slide.img" :alt="slide.img" style="object-fit: fill;height:100%;" :style="`max-width:${slider.horizontal.img_width}px;width:${slider.horizontal.img_width}px`">
            </div>
            <div style="border-radius:0;display: flex;flex-direction: column;font-size:14px;justify-content: space-evenly;" :style="`width:calc(100% - ${slider.horizontal.img_width}px);`">
              <p  style="padding:5px;width:100%;border-radius:0;min-height:auto;height:auto;margin:0;overflow-wrap: break-word;" v-html="slide.text"></p>
              <!--                  <a :href="slide.link" style="inline-size: 120px;overflow-wrap: break-word;text-align: center;">-->
              <!--                    {{slide.text_link}}-->
              <!--                  </a>-->
              
            </div>
</div>
          </swiper-slide>
        </swiper>
        <swiper
            v-if="slider.type === 'vertical'"
            ref="mySwiper"
            :options="swiperOptions"
            @clickSlide="handleClickSlide(slideGroupIndex)"
        >
          <swiper-slide v-for="(slide,i) in JSON.parse(JSON.stringify(slider.slides.filter(x => x.img)))"
                        :key="i" :style="`width:${slider.vertical.width}px;`"
                        >
            <div style="display:flex;flex-direction:column;" 
                        :style="`background:${slider.bg_group};cursor:${slide.useLink?'pointer':'default'};width:${slider.vertical.width}px;border:1px solid ${slider.border_color};border-radius:${landing.menu.radius}px;min-height:${slider.vertical.height}px;`">

            <div  :class='`sliderVertical_${slideGroupIndex}_${i}`' style="width:100%;" :style="`border-radius:${slider.radius}px;`">
              <img :src="slide.img" :alt="slide.img" style="width:100%;object-fit: cover;" :style="`height:${slider.vertical.img_height}px;border-top-left-radius:${slider.radius}px;border-top-right-radius:${slider.radius}px;`">
            </div>
            <div style="border-radius:0;display: flex;flex-direction: column;font-size:14px;justify-content: space-evenly;">
              <p  style="padding:5px;border-radius:0;min-height:auto;height:auto;margin:0;overflow-wrap: break-word;" v-html="slide.text"></p>
            </div>
            </div>

          </swiper-slide>
        </swiper>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import 'swiper/swiper-bundle.css'
import "swiper/modules/pagination/pagination.min.css"
import SwiperCore, {
  Pagination
} from 'swiper';
import { Swiper, SwiperSlide} from 'vue-awesome-swiper'
SwiperCore.use([Pagination]);
export default {
  props:['slider_menu','active_slider'],
  name:'SliderPreview',
  data(){
    const that = this
    return{
      sliderMenu:[],
      swiperOptions:{
        slidesPerView: 'auto',
        spaceBetween: 15,
        on:{
          click: function() {
            that.sliderIndex = this.clickedIndex
          }
        }
      },
      sliderIndex:0,
    }
  },
  components:{
    Swiper,
    SwiperSlide
  },
  computed:{
    ...mapState(['landing']),

  },
  created(){
    this.slider_menu.forEach(element => {
      element.slides.forEach((slide,ind) => {
        slide.id = ind;
      })
    });
    this.sliderMenu = this.slider_menu;
  },
  methods:{
    handleClickSlide(sliderGroupIndex){
      if(this.slider_menu[sliderGroupIndex].slides[this.sliderIndex].useLink){
        window.open(this.slider_menu[sliderGroupIndex].slides[this.sliderIndex].link,'_blank');
      }
    },
  },
}
</script>

<style>

</style>