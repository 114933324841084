var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"typeDynamicPreview"},[(_vm.selectedContent)?_c('div',[_c('div',[(_vm.selectedContent.type === 0)?_c('div',{style:(_vm.liningStyles +
          "margin-top:" + (_vm.landing.advanced.global.active_header_inside ||
            _vm.landing.advanced.global.active_logo_inside
              ? '20px'
              : ((+_vm.landing.branding.topMenu.styles.size * 2.5) + "px")) + ";padding:15px;min-height:300px;margin-left:" + (_vm.landing.branding.lining_margin ? '10px' : '0') + ";margin-right:" + (_vm.landing.branding.lining_margin ? '10px' : '0') + ";")},[_c('typeHtmlPreview',{attrs:{"content":_vm.selectedContent.content[0]}}),_c('FormOrderPreview',{attrs:{"formOrder":_vm.selectedContent.content[0].form}}),_c('SliderMenuPreview',{attrs:{"active_slider":_vm.selectedContent.content[0].active_slider,"slider_menu":_vm.selectedContent.content[0].slider}})],1):_vm._e(),(_vm.selectedContent.type === 2)?_c('div',[(_vm.listing.page)?[_c('div',{style:(("padding:" + (_vm.landing.branding.lining_margin ? '0 10px' : '0')))},[_c('typeListingDetailPreview',{attrs:{"item":_vm.item,"content":_vm.selectedContent.content[2],"listing":_vm.listing}})],1)]:[_c('div',{staticClass:"listing__list",style:(_vm.liningStyles +
              "margin-top:" + (_vm.landing.advanced.global.active_header_inside ||
                _vm.landing.advanced.global.active_logo_inside
                  ? '20px'
                  : ((+_vm.landing.branding.topMenu.styles.size * 2.5) + "px")) + ";margin-left:" + (_vm.landing.branding.lining_margin ? '10px' : '0') + ";margin-right:" + (_vm.landing.branding.lining_margin ? '10px' : '0') + ";min-height:300px;")},[_c('typeListingListPreview',{attrs:{"item":_vm.item,"content":_vm.selectedContent.content[2],"type":'listing'}}),_c('SliderMenuPreview',{attrs:{"active_slider":_vm.selectedContent.content[2].active_slider,"slider_menu":_vm.selectedContent.content[2].slider}})],1)]],2):_vm._e(),(_vm.selectedContent.type === 3)?_c('div',[_c('type-gallery-preview',{attrs:{"content":_vm.selectedContent.content[3]}})],1):_vm._e()])]):_c('div',{staticStyle:{"border-radius":"5px","background":"#fff","display":"flex","justify-content":"center","align-items":"center","min-height":"200px","margin":"65px 15px 0 15px","padding":"15px"}},[_vm._v(" Choose your button content ")])])}
var staticRenderFns = []

export { render, staticRenderFns }