<template>
  <div>
    <div
      v-for="(listing_item, index) in content.content.listing[
        listing.listingGroup
      ].content"
      :key="index"
    >
      <div
        class="listing-full"
        v-if="
          (listing.index == index && listing.subListing === null) ||
          listing.subListing === index
        "
        :style="
          liningStyles(listing_item) +
          `margin-top:${listing_item.img.length ? '0' : `${+landing.branding.topMenu.styles.size * 2.5}px`};min-height:300px;`
        "
        style="
          position: relative;
          display: flex;
          flex-direction: column;
          align-items: center;
          overflow: hidden;
        "
      >
        <div
          :style="`width:${
            landing.branding.lining_margin ? '330px' : '345px'
          };height:${item.styles.detail_listing_img_height}px;overflow:hidden;`"
          v-show="listing_item.img.includes('1')"
        >
          <div
            style="height: 100%; overflow: hidden"
            :style="`width:${
              landing.branding.lining_margin ? '330px' : '345px'
            };`"
            class="preview_detail"
          ></div>
        </div>
        <swiper
          ref="mySwiper"
          :direction="'horizontal'"
          :pagination="{ clickable: true }"
          :options="swiperOptionsListing"
          style="width: 100%; overflow: hidden;position: relative"
          :style="`height:${item.styles.detail_listing_img_height}px;`"
          v-if="listing_item.img.length && !listing_item.img.includes('1') || listing_item.video"
          @slideChange="changeSwiperIndex"
        >
          <swiper-slide v-if="listing_item.video">
            <video :width="345" :height="+item.styles.detail_listing_img_height" controls>
              <source :src="listing_item.video" type="video/mp4">
              Your browser does not support the video tag.
            </video>
          </swiper-slide>
          <swiper-slide v-for="img in listing_item.img" :key="img">
            <img
              :src="img"
              alt=""
              :style="`height:${
                item.styles.detail_listing_img_height
              }px;width:${landing.branding.lining_margin ? '330px' : '345px'};`"
            />
          </swiper-slide>
            <div class="swiper-pagination" slot="pagination" />
        </swiper>
        <div
          class="listing__play_video listing__play_video_with_img"
          :style="`top:${
            +item.styles.detail_listing_img_height / 2 - 25
          }px;position:absolute;z-index:100;`"
          v-if="
            listing_item.video_link &&
            listing_item.img.length &&
            activeIndex === 0
          "
          @click="openVideo(listing_item.video_link)"
        ></div>
        <div
          class="listing__play_video listing__play_video_without_img"
          v-if="listing_item.video_link && listing_item.img.length === 0"
          @click="openVideo(listing_item.video_link)"
        ></div>
        <!-- <h1 class="listing-full__name">
          {{ listing_item.name }}
        </h1> -->
        <div
            class="detail_description"
          style="height: auto; min-height: 100px; width: 100%; padding: 10px 20px;"
          v-html="htmlData(listing_item.full_descr)"
        ></div>
        <FormOrderPreview :formOrder="listing_item.form" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
import FormOrderPreview from "./FormOrderPreview.vue";
import "swiper/swiper-bundle.css";
import "swiper/modules/pagination/pagination.min.css";
import SwiperCore, { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
SwiperCore.use([Pagination]);
export default {
  name: "typeListingDetailPreview",
  components: {
    FormOrderPreview,
    Swiper,
    SwiperSlide,
  },
  computed: {
    ...mapState(["landing"]),
    mySwiper: () => {
      return this.$refs.mySwiper.swiper;
    },
  },
  props: ["item", "content", "listing"],
  data() {
    return {
      activeIndex: 0,
      swiperOptionsListing: {
        grabCursor: true,
        centeredSlides: true,
        pagination: {
          el: ".swiper-pagination",
          type: "bullets",
          clickable: true,
        },
      },
    };
  },
  methods: {
    liningStyles(listing_item) {
      let styles;
      if (this.landing.branding.bg_lining_img) {
        styles = `background-image:url(${this.landing.branding.bg_lining_img});background-size:cover;background-repeat:no-repeat;`;
      } else {
        styles = `background-color:${this.landing.branding.bg_lining_color};`;
      }
      if (listing_item.img.length) {
        styles += `border-bottom-left-radius:${this.landing.branding.lining_radius}px;border-bottom-right-radius:${this.landing.branding.lining_radius}px;`;
      } else {
        styles += `border-radius:${this.landing.branding.lining_radius}px;`;
      }
      return styles;
    },
    changeSwiperIndex() {
      this.activeIndex = this.$refs["mySwiper"][0].$swiper.activeIndex;
    },
    openVideo(link) {
      window.open(link, "_blank");
    },
    timeNow() {
      this.datenow = moment().format("HH:mm");
      setInterval(() => {
        this.datenow = moment().format("HH:mm");
      }, 1000);
    },
    htmlData(data, type) {
      if (data) {
        const weatherInd = data.split(" ").indexOf(":weather:");
        let result = data.split(" ");
        if (weatherInd && this.landing.advanced.global.show_weather) {
          const newArr = result;
          newArr[weatherInd] =
            this.landing.advanced.global.weather.week.daily[0].temp.day.toFixed(
              0
            ) + " ℃";
          result = newArr;
        }
        const timeInd = data.split(" ").indexOf(":time:");
        if (timeInd) {
          const newArr = result;
          newArr[timeInd] = this.datenow;
          result = newArr;
        }
        const dateInd = data.split(" ").indexOf(":date:");
        if (dateInd) {
          const newArr = result;
          newArr[dateInd] = this.dateToday;
          result = newArr;
        }
        const user_name = data.split(" ").indexOf(":name:");
        if (
          user_name &&
          this.landing.advanced.global.auth &&
          this.landing.advanced.auth.name &&
          type === "title"
        ) {
          const newArr = result;
          newArr[user_name] = "Agent";
          result = newArr;
        }
        const user_surname = data.split(" ").indexOf(":surname:");
        if (
          user_surname &&
          this.landing.advanced.global.auth &&
          this.landing.advanced.auth.name &&
          type === "title"
        ) {
          const newArr = result;
          newArr[user_surname] = "Smith";
          result = newArr;
        }
        const user_birthday = data.split(" ").indexOf(":birthday:");
        if (
          user_surname &&
          this.landing.advanced.global.auth &&
          this.landing.advanced.auth.name &&
          type === "title"
        ) {
          const newArr = result;
          newArr[user_birthday] = "24.03.1999";
          result = newArr;
        }
        const user_room = data.split(" ").indexOf(":room:");
        if (
          user_surname &&
          this.landing.advanced.global.auth &&
          this.landing.advanced.auth.name &&
          type === "title"
        ) {
          const newArr = result;
          newArr[user_room] = "707";
          result = newArr;
        }

        const user_auth = data.split(" ").indexOf(":auth:");
        if (user_auth && this.landing.advanced.global.auth && !type) {
          const newArr = result;
          newArr[user_auth] = `<a href="#">Login</a>`;
          result = newArr;
        }
        const user_logout = data.split(" ").indexOf(":logout:");
        if (user_logout && this.landing.advanced.global.auth && !type) {
          const newArr = result;
          newArr[user_logout] = `<a href="#">Logout</a>`;
          result = newArr;
        }
        return result.join(" ");
      }
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.detail_description ul{
  margin-left:15px;
}
</style>
