var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',_vm._l((_vm.content.content.listing[
      _vm.listing.listingGroup
    ].content),function(listing_item,index){return _c('div',{key:index},[(
        (_vm.listing.index == index && _vm.listing.subListing === null) ||
        _vm.listing.subListing === index
      )?_c('div',{staticClass:"listing-full",staticStyle:{"position":"relative","display":"flex","flex-direction":"column","align-items":"center","overflow":"hidden"},style:(_vm.liningStyles(listing_item) +
        "margin-top:" + (listing_item.img.length ? '0' : ((+_vm.landing.branding.topMenu.styles.size * 2.5) + "px")) + ";min-height:300px;")},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(listing_item.img.includes('1')),expression:"listing_item.img.includes('1')"}],style:(("width:" + (_vm.landing.branding.lining_margin ? '330px' : '345px') + ";height:" + (_vm.item.styles.detail_listing_img_height) + "px;overflow:hidden;"))},[_c('div',{staticClass:"preview_detail",staticStyle:{"height":"100%","overflow":"hidden"},style:(("width:" + (_vm.landing.branding.lining_margin ? '330px' : '345px') + ";"))})]),(listing_item.img.length && !listing_item.img.includes('1') || listing_item.video)?_c('swiper',{ref:"mySwiper",refInFor:true,staticStyle:{"width":"100%","overflow":"hidden","position":"relative"},style:(("height:" + (_vm.item.styles.detail_listing_img_height) + "px;")),attrs:{"direction":'horizontal',"pagination":{ clickable: true },"options":_vm.swiperOptionsListing},on:{"slideChange":_vm.changeSwiperIndex}},[(listing_item.video)?_c('swiper-slide',[_c('video',{attrs:{"width":345,"height":+_vm.item.styles.detail_listing_img_height,"controls":""}},[_c('source',{attrs:{"src":listing_item.video,"type":"video/mp4"}}),_vm._v(" Your browser does not support the video tag. ")])]):_vm._e(),_vm._l((listing_item.img),function(img){return _c('swiper-slide',{key:img},[_c('img',{style:(("height:" + (_vm.item.styles.detail_listing_img_height) + "px;width:" + (_vm.landing.branding.lining_margin ? '330px' : '345px') + ";")),attrs:{"src":img,"alt":""}})])}),_c('div',{staticClass:"swiper-pagination",attrs:{"slot":"pagination"},slot:"pagination"})],2):_vm._e(),(
          listing_item.video_link &&
          listing_item.img.length &&
          _vm.activeIndex === 0
        )?_c('div',{staticClass:"listing__play_video listing__play_video_with_img",style:(("top:" + (+_vm.item.styles.detail_listing_img_height / 2 - 25) + "px;position:absolute;z-index:100;")),on:{"click":function($event){return _vm.openVideo(listing_item.video_link)}}}):_vm._e(),(listing_item.video_link && listing_item.img.length === 0)?_c('div',{staticClass:"listing__play_video listing__play_video_without_img",on:{"click":function($event){return _vm.openVideo(listing_item.video_link)}}}):_vm._e(),_c('div',{staticClass:"detail_description",staticStyle:{"height":"auto","min-height":"100px","width":"100%","padding":"10px 20px"},domProps:{"innerHTML":_vm._s(_vm.htmlData(listing_item.full_descr))}}),_c('FormOrderPreview',{attrs:{"formOrder":listing_item.form}})],1):_vm._e()])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }